import '@babel/polyfill'
import 'mutationobserver-shim'
import './plugins/bootstrap-vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import bootstrapVue from './plugins/bootstrap-vue'
import global from './assets/global.css'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  global,
  bootstrapVue
}).$mount('#app')
